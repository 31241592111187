import { EmailIcon, LocationIcon, PhoneIcon } from 'shared/icon/icon';

const Footer = () => {
	const handleClick = (event: any) => {
		event.preventDefault(); // Prevent the default link behavior
		window.open('https://www.sitelock.com/verify.php?site=prarthanaglobalschool.com', 'SiteLock', 'width=600,height=600,left=160,top=170');
	};

	return (
		<div className='footer-wrapper flex flex--column width--full mt--150'>
			<div className='flex justify__content--evenly footer--container'>
				<div className='flex flex--column footer--box'>
					<p className='font-size--17 font-weight--700'>About Us</p>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/aboutschool'>
						About Prarthana School
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/mission&vision'>
						Mission & vision
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/chairman'>
						Chairman's Message
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/director'>
						Director's Message
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/rules&regulation'>
						Rules & Regulation
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/schoolsystem'>
						School System
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/cbsemandatorydiscolsure'>
						CBSE Mandatory Disclosure
					</a>
				</div>
				<div className='flex flex--column footer--box'>
					<p className='font-size--17 font-weight--700'>Academics</p>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/academics/16'>
						List Of Holidays
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/academics/10'>
						Academic Calendar
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/academics/17'>
						CCA & Competitions
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/academics/18'>
						National Education Policy
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/academics/19'>
						Exam Time Table
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/fees/9'>
						Fees
					</a>
				</div>
				<div className='flex flex--column footer--box'>
					<p className='font-size--17 font-weight--700'>Activity</p>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/cocurricularactivity'>
						Co-Curricular Activities
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/sports&game'>
						Sports & Game
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/art'>
						Art Room
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/music'>
						Music Room
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/dance'>
						Dance Room
					</a>
					<a className='footer-link-text text-decoration--none text--grey font-weight--500 mb--10' href='/housing&dining'>
						Housing & Dining
					</a>
				</div>
				<div className='flex flex--column footer--box'>
					<p className='font-size--17 font-weight--700'>Get In Touch</p>
					<div className='flex align__items--center mb--20'>
						<a href='tel:+917770096444' className='flex footer-link-text text--grey font-weight--500'>
							<PhoneIcon className='width--20-px mr--20' />
							<span>+91 7770096444</span>
						</a>
					</div>
					<div className='flex align__items--center mb--20'>
						<LocationIcon className='location-icon width--30-px mr--20' />
						<span className='footer-link-text text--grey font-weight--500'>
							Prarthana Global School,At & Po- Madapurkampa,Modasa, Gujarat 383315{' '}
						</span>
					</div>
					<div className='flex align__items--center'>
						<EmailIcon className='width--20-px mr--20' />
						<a href='mailto:pgsmodasa@gmail.com' className='text-decoration--none footer-link-text text--grey font-weight--500'>
							pgsmodasa@gmail.com{' '}
						</a>
					</div>
				</div>
			</div>
			<div className='footer-bottom-text-wrapper flex justify__content--evenly p--25 copy-right--container mt--60 align__items--center'>
				<p className='bottom-text text--grey font-weight--500'>Copyright © 2024 Prarthana Global School, All Right Reserved.</p>
				<a href='#' onClick={handleClick}>
					<img className='img-fluid' alt='SiteLock' title='SiteLock' src='https://shield.sitelock.com/shield/prarthanaglobalschool.com' />
				</a>
				<p className='bottom-text text--grey font-weight--500'>
					Developed By:{' '}
					<a href='mailto:yashupadhyay312@gmail.com' className='bottom-text text--grey font-weight--500' title='Email-Id'>
						Yash Upadhyay,
					</a>
					<a href='mailto:ishitsoni1418@gmail.com' className='bottom-text text--grey font-weight--500' title='Email-Id'>
						Ishit Soni
					</a>
				</p>
				{/* <p className='bottom-text text--grey font-weight--500'>Last Update Date: 07-08-2023</p> */}
			</div>
		</div>
	);
};

export default Footer;
